import Icon, { Icons } from '../icons/Icon';
import NoPrefetchLink from '../NoPrefetchLink';

function AllArticlesButton() {
  return (
    <NoPrefetchLink
      href="/aktuelt"
      className="bg-background border-1 group inline-flex max-w-max items-center gap-3 rounded-[24px] border border-black/5 px-6 py-2 duration-300 hover:rounded-md hover:bg-[#ddd9d3] active:rounded-[24px] active:border-black/20"
    >
      <span className="text-lg font-bold">Se alle artikler</span>
      <Icon
        icon={Icons.ARROW_RIGHT}
        className=" group-active:translate-x-none duration-300 group-hover:translate-x-2"
      />
    </NoPrefetchLink>
  );
}

export default AllArticlesButton;
