import { TitleDescriptionLinksType } from '../types';
import { buttonVariants } from './Button';
import NoPrefetchLink from './NoPrefetchLink';

interface AnnouncementProps {
  value: TitleDescriptionLinksType;
}

function Announcement({ value }: AnnouncementProps) {
  if (!value.title) return null;
  return (
    <article className="flex flex-col items-center gap-4 bg-[rgb(237,243,255)] px-8 py-10 text-center duration-300 md:p-14 lg:p-14">
      <div className="flex flex-col items-center justify-center gap-4">
        <h2
          className="text-display-3 md:text-display-2 font-semibold"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          style={{ textWrap: 'balance' }}
        >
          {value.title}
        </h2>
        {value?.description && <p className="max-w-2xl lg:text-lg">{value.description}</p>}
      </div>
      {Array.isArray(value?.links) && !!value?.links.length && (
        <div className="mt-4 flex flex-wrap items-center justify-center gap-3">
          {value.links.map((link, index) => {
            const variant = index === 0 ? 'primary' : 'secondary';

            return (
              <NoPrefetchLink key={link._key} href={link.href || ''} className={buttonVariants({ variant })}>
                {link.linkText}
              </NoPrefetchLink>
            );
          })}
        </div>
      )}
    </article>
  );
}

export default Announcement;
