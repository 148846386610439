import { ArticleCardType } from '../types';
import Section from './Section';
import AllArticlesButton from './cards/AllArticlesButton';
import ArticleCard from './cards/ArticleCard';

export const FeatureArticleList = ({
  title,
  articles,
}: {
  title?: string;
  articles: ArticleCardType[];
}) => {
  return (
    <Section>
      <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">
        {title || 'Relaterte artikler'}
      </h2>

      <div className="mb-10 grid gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {articles.map((article) => {
          return <ArticleCard key={article.title} article={article} />;
        })}
      </div>
      <AllArticlesButton />
    </Section>
  );
};

export default FeatureArticleList;
