import { ImageWithAltType } from '../../types';
import cn from '../../utils/cn';
import { buttonVariants } from '../Button';
import { Image } from '../Image';
import Overline from '../Overline';
import NoPrefetchLink from '../NoPrefetchLink';

interface ArticleCardBigProps {
  overline?: string;
  title: string;
  description?: string;
  href?: string;
  linkText?: string;
  image?: ImageWithAltType;
  flip?: boolean;
  noButton?: boolean;
}

function ArticleCardBig({
  title,
  description,
  href,
  image,
  linkText,
  overline,
  flip,
  noButton,
}: ArticleCardBigProps) {
  return (
    <div className="@container group relative h-full  overflow-hidden rounded-md">
      <div className="@2xl:grid-cols-2 grid h-full">
        <div
          className={cn(
            `bg-background @6xl:p-16 flex h-full flex-col justify-center p-8 duration-300  sm:gap-2`,
            flip ? 'order-2' : '',
            noButton ? 'cursor-pointer group-hover:bg-[#ddd9d3] group-active:bg-[#ada9a5]' : '',
          )}
        >
          <div className="flex flex-col">
            <Overline text={overline} />
            {noButton ? (
              <NoPrefetchLink
                href={href || ''}
                className="after:contents:'' after:absolute after:inset-0 after:z-10 after:outline after:outline-1 after:outline-transparent focus:outline-none focus:focus-visible:after:outline-black"
              >
                <span className="text-2xl font-semibold sm:text-3xl">{title}</span>
              </NoPrefetchLink>
            ) : (
              <span className="text-2xl font-semibold sm:text-3xl">{title}</span>
            )}
          </div>
          <p className="line-clamp-3 pt-2 lg:text-lg">{description}</p>
          {!noButton && (
            <NoPrefetchLink
              href={href || ''}
              className={`${buttonVariants({ variant: 'primary-dark' })} mt-6`}
            >
              {linkText}
            </NoPrefetchLink>
          )}
        </div>

        {image?.asset && (
          <Image
            src={image}
            width={(500 * 16) / 9}
            height={500}
            className={cn(
              'aspect-video h-full object-cover duration-300',
              noButton ? 'group-hover:scale-105' : '',
            )}
          />
        )}
      </div>
    </div>
  );
}

export default ArticleCardBig;
