import { FiArrowUpRight } from 'react-icons/fi';
import { RiArrowRightLine } from 'react-icons/ri';
import cn from '../../utils/cn';
import NoPrefetchLink from '../NoPrefetchLink';

interface LinkCardProps {
  title: string;
  href: string;
  description?: string;
  className?: string;
  color?: 'beige-dark' | 'info' | 'beige-light' | 'white';
  rounded?: boolean;
  fullwidth?: boolean;
}

function LinkCard({
  title,
  href,
  className,
  color = 'info',
  description,
  rounded,
  fullwidth,
}: LinkCardProps) {
  const getColor = () => {
    switch (color) {
      case 'white':
        return 'hover:bg-[#aac8ff] active:bg-[#83afff] bg-white border-[#83afff]/20';
      case 'info':
        return 'hover:bg-[#aac8ff] active:bg-[#83afff] bg-info-container border-[#83afff]/20';
      case 'beige-light':
        return 'hover:bg-[#ddd9d3] active:bg-[#ada9a5] bg-[#f9f7f4] border-[#ada9a5]/20';
      case 'beige-dark':
        return 'hover:bg-[#ddd9d3] active:bg-[#ada9a5] bg-background border-[#ada9a5]/20';
      default:
        return '';
    }
  };

  if (!href || !title) return null;

  const external = href.includes('https');

  return (
    <NoPrefetchLink
      href={href}
      className={cn(
        'group flex items-center justify-between gap-5 border px-8 py-5 duration-300 sm:text-2xl',
        getColor(),
        rounded ? 'rounded-[4px]' : 'rounded-sm',
        fullwidth ? 'w-full' : '',
        className,
      )}
    >
      <div className="flex flex-col gap-2">
        <span className="text-lg font-semibold md:text-xl lg:text-2xl">{title}</span>
        {description && <p className="text-base md:text-lg">{description}</p>}
      </div>
      {external ? (
        <FiArrowUpRight className="h-6 w-6 flex-shrink-0 duration-300 group-hover:-translate-y-1 group-hover:translate-x-1 md:h-7 md:w-7 md:translate-x-0" />
      ) : (
        <RiArrowRightLine className="h-6 w-6 flex-shrink-0 duration-300 group-hover:translate-x-3 md:h-7 md:w-7 md:translate-x-0" />
      )}
    </NoPrefetchLink>
  );
}

export default LinkCard;
