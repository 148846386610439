import { ArticleCardType } from '../../types';
import { Image } from '../Image';
import NoPrefetchLink from '../NoPrefetchLink';

interface ArticleCardProps {
  article: ArticleCardType;
}

function ArticleCard({ article }: ArticleCardProps) {
  const { image, href, title, description, tagName, overline } = article;
  return (
    <div className="@container group relative h-full cursor-pointer rounded-md duration-300">
      <div className="bg-background l h-full duration-300 hover:bg-[#ddd9d3] active:bg-[#ada9a5]">
        <div className="overflow-hidden">
          {image?.asset && (
            <Image
              src={image}
              width={(600 / 9) * 16}
              height={600}
              className="aspect-video object-cover duration-300 group-hover:scale-105"
            />
          )}
        </div>

        <div className="flex flex-col p-6">
          <span className="text-tertiary-variant mb-1 text-base font-bold sm:text-lg lg:text-xl">
            {overline || tagName}
          </span>

          <NoPrefetchLink
            href={href || ''}
            className="after:contents:'' after:absolute after:inset-0 after:outline after:outline-1 after:outline-transparent focus:outline-none focus:focus-visible:after:outline-black"
          >
            <span className="text-2xl font-bold">{title}</span>
          </NoPrefetchLink>
          <p className="mt-4 line-clamp-3 lg:text-lg">{description}</p>
        </div>
      </div>
    </div>
  );
}

export function ArticleCardHorizontal({ article }: ArticleCardProps) {
  const { image, href, title, description, tagName, overline } = article;
  return (
    <div className="@container group relative h-full overflow-hidden rounded-md duration-300">
      <div className="bg-background flex h-full duration-300 hover:bg-[#ddd9d3] active:bg-[#ada9a5]">
        {image?.asset && (
          <Image
            src={image}
            width={250}
            height={250}
            className="aspect-square object-cover duration-300 group-hover:scale-105"
          />
        )}
        <div className="@6xl:p-16 flex flex-grow flex-col justify-center px-8 py-6 lg:py-6">
          <span className="text-tertiary-variant mb-1 text-base font-bold sm:text-lg lg:text-xl ">
            {overline || tagName}
          </span>

          <NoPrefetchLink
            href={href || ''}
            className="after:contents:'' after:absolute after:inset-0 after:outline after:outline-1 after:outline-transparent focus:outline-none focus:focus-visible:after:outline-black"
          >
            <span className="text-2xl font-bold">{title}</span>
          </NoPrefetchLink>
          <p className="mt-4 line-clamp-3 lg:text-lg">{description}</p>
        </div>
      </div>
    </div>
  );
}

export default ArticleCard;
