import { ImageWithAltType } from '../../types';
import { Image } from '../Image';
import Overline from '../Overline';
import Icon, { Icons } from '../icons/Icon';
import NoPrefetchLink from '../NoPrefetchLink';

interface CampaignCardProps {
  overline?: string;
  title: string;
  description: string;
  href?: string;
  image: ImageWithAltType;
}

function CampaignCard({ overline, title, description, href, image }: CampaignCardProps) {
  if (!title || !href) return null;
  return (
    <div className="@container group relative my-2 h-full w-full cursor-pointer overflow-hidden rounded-sm duration-300 md:my-8">
      <div className="@3xl:flex-row flex h-full flex-col">
        {image?.asset && (
          <Image
            src={image}
            width={(500 * 16) / 9}
            height={500}
            className="@3xl:max-w-md aspect-video object-cover duration-300"
          />
        )}
        <div className="@sm:p-10 @6xl:p-16 @6xl:gap-8 flex w-full items-center justify-between gap-4 bg-[#efe9e1] p-6 duration-300 group-hover:bg-[#ddd9d3] group-active:bg-[#ada9a5]">
          <div className="flex flex-col">
            <Overline text={overline} />
            <NoPrefetchLink
              href={href || ''}
              className="after:contents:'' after:absolute after:inset-0 after:outline after:outline-1 after:outline-transparent focus:outline-none focus:focus-visible:after:outline-black"
            >
              <span className="text-2xl font-semibold sm:text-3xl">{title}</span>
            </NoPrefetchLink>
            <p className="line-clamp-3 pt-2 lg:text-lg">{description}</p>
          </div>

          <Icon
            icon={Icons.ARROW_RIGHT}
            className="@6xl:w-6 @6xl:h-6 min-h-[24px] min-w-[24px] duration-300 group-hover:translate-x-1"
          />
        </div>
      </div>
    </div>
  );
}

export default CampaignCard;
