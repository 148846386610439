import { ArticleCardType } from '../types';
import ArticleCard, { ArticleCardHorizontal } from './cards/ArticleCard';

export const StandardArticleList = ({ articles }: { articles: ArticleCardType[] }) => {
  return articles.length > 0 ? (
    <section className="max-w-8xl mx-auto mb-16 mt-16 px-6 sm:mb-20 sm:px-10 lg:px-20">
      <div className="mx-auto max-w-4xl">
        <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">Nyttig å vite</h2>
        <div className="grid w-full gap-5">
          {articles.map((article: any) => {
            return (
              <>
                <div className="max-sm:hidden">
                  <ArticleCardHorizontal article={article} />
                </div>
                <div className="sm:hidden">
                  <ArticleCard article={article} />
                </div>
              </>
            );
          })}
        </div>
      </div>
    </section>
  ) : null;
};

export default StandardArticleList;
